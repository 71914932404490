<template>
  <div>
    <div class="small-intro pb-lg-0">
        <div class="container">
            <h1 class="light">
                Partnerships
            </h1>
        </div>
    </div>
    <div class="container">
        <div class="row" v-if="this.partners">
            <div class="partner col-lg-4"  v-for="(partner,index) of partners" :key="index">
                <div class="image" :style="getStylePostImage(partner)"></div>
                <h1>{{partner.attributes.title}}</h1>
                <a class="icon-website" target="_blank" :href="partner.attributes.field_partner_website.uri"><img src="@/assets/icon-website.svg" width="19" /> Official website</a>
                <router-link class="cta cta-primary mt-lg-4 mt-3" :to="{ path: '/projects/?partner='+ partner.id}">view project</router-link>
            </div>
        </div>
    </div>
  </div>
</template>
<script>

export default {
    name: "partnership",
    components: {
    },
    data() {
        return {
            urlData:`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/partner?include=field_shared_image`,
            partners:[],
        };
    },
    computed:{
        projectPageFiltered(){
            return "Null"
        }
        
    },
    methods:{
        getStylePostImage(partner) {
            let style = {};
            if (partner.img) {
                style.backgroundImage = "url("+ process.env.VUE_APP_ENDPOINT + partner.img.attributes.uri.url + ")";
            }
            return style;
        },
        fetchData(){
            fetch(this.urlData, {
                method: "GET",
                mode: "cors",
            })
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                let partners = json.data;
                var included = json.included;

                //console.log(included);

                partners = partners.map((partner) => {
                    if (partner.relationships.field_shared_image.data) {
                    var imgId = partner.relationships.field_shared_image.data.id;
                    partner.img = included.find((file)=>{
                        return file.id === imgId;
                    })
                    }
                    return partner;
                });
                this.partners = partners;
            })
            .catch(function (error) {
                console.log("Request failed", error);
            });
        },
    },
    created(){
        this.fetchData()
    }
}
</script>
<style lang="scss" scoped>
a img{
        position: relative;
    top: -2px;
    left: -5px;
}
.cta {
    padding: 7px 28px;
    font-weight: 400;
    text-transform: uppercase;
}
.partner {
    height: 356px;
    max-width: 356px;
    max-height: 356px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin: 12px auto;
    .image {
        width: 200px;
        background-position:center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 130px;
    }
    h1 {
        font-size: 1.625rem;
    }
}

@media (max-width:1200px){
    .partner {
        width: 296px;
        height: 296px;
        padding: 0;
        max-width: none;
        max-height: none;
  
    }
}
@media (max-width:768px){

        .partner{
            width: 100%;
            max-width: none;
            padding: 20px;
            min-height: 247px;
            height: auto;
    }
}
</style>